
.theme-button {
  border: 3px solid var(--highlight);
  border-left: 3px dotted var(--highlight);
  margin-bottom: 8px;
  margin-right: 4px;
  margin-left: 4px;
  border-radius: 100%;
  cursor: pointer;
  background-color: transparent;
  color: var(--highlight);
  transition: all 0.2s linear;
  flex: none;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.theme-button-loading {
  animation: 5s rotate infinite;
}
.theme-button-loading > div {
  animation: 5s rotate-opposite infinite;
}

.theme-button-loading > div {
  margin-top: 0em !important;
}

.theme-button:disabled {
  opacity: 0.5;
  background-color: transparent;
}

.theme-button:disabled:hover {
  transform: none;
  background-color: transparent;
}

.theme-button:disabled:hover > * {
  transform: none;
}

.rotate-on-hover:hover {
  transform: rotate(-10deg);
}

.rotate-on-hover div {
  transition: all 0.2s linear;
}

.rotate-on-hover:hover div {
  transform: rotate(10deg);
}

.theme-button .icon {
  filter: invert(89%) sepia(100%) brightness(85%) saturate(1000%) hue-rotate(-20deg);
}

.big-theme-button {
  border-radius: 20px;
}

.plus-minus-fade-in {
  display: inline-block;
  text-align: center;
  animation: minus-appear 0.5s forwards;
}

.plus-minus-fade-in-small {
  display: inline-block;
  text-align: center;
  animation: minus-appear-small 0.5s forwards;
}

@keyframes minus-appear {
  0% {
    opacity: 0;
    width: 0px;
  }
  90% {
    width: 32px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    width: 32px;
  }
}

@keyframes minus-appear-small {
  0% {
    opacity: 0;
    width: 0px;
  }
  90% {
    min-width: 16px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    min-width: 16px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(350deg);
  }
}

@keyframes rotate-opposite {
  0% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(-350deg);
  }
}