#navbar {
  background-color: var(--medium);
  width: 10em;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 999;
}

.navbar-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-block-start: 0;
  padding-inline-start: 8px;
}

.navbar-item {
  margin-top: 0.5em;
  text-align: left;
}

#navbar a {
  margin-bottom: 20px;
  text-decoration: none;
  color: var(--light);
  transition: all 0.1s linear;
}

#navbar a:hover {
  color: var(--highlight);
}

.navbar-item:hover .icon {
  filter: invert(89%) sepia(100%) brightness(85%) saturate(1000%) hue-rotate(-20deg);
}

.navbar-item .icon {
  transition: all 0.1s linear;
}

@media screen and (max-width: 800px) {
  #navbar {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  #navbar-mobile {
    display: none;
  }
  
  #navbar-margin {
    margin-left: 10em;
  }

  .navbar-graphics {
    left: 10em !important;
  }
}

#navbar-mobile {
  text-align: left;
  background-color: var(--dark);
}

.mobile-navbar-button {
  width: 3em;
  height: 3em;
  margin-left: 1.5em;
  background-color: var(--medium);
  color: var(--light);
  border-radius: 4px;
  z-index: 999;
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-navbar-button.open {
  filter: brightness(70%);
  border: 1px solid var(--light);
}

.navbar-list-mobile {
  width: 100%;
  background-color: var(--dark);
  padding-left: 1em;
  animation: 1s rolldown-nav forwards;
}

.flex-direction-by-screensize {
  flex-direction: row;
}

@media screen and (max-width: 1300px) {
  .flex-direction-by-screensize {
    flex-direction: column;
  }
  
}

.order-fade-in {
  animation: fade-in 0.3s forwards;
}

.order-fade-out {
  animation: fade-out 0.3s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rolldown-nav {
  0% {
    height: 0em;
    transform: translateY(-20em);
  }

  100% {
    height: 14em;
    transform: translateY(0);
  }
}