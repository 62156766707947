* {
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-tap-highlight-color: transparent;
}

html {
  background-color: var(--dark);
}
