@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

:root {
  --dark: #050505;
  --medium: #2d2b2b;
  --medium-light: #393636;
  --light-medium: #646464;
  --highlight: #FFCC3E;
  --highlight-semi: #d0a734;
  --dark-highlight: #504116;
  --light: #FFFFFF;
  --darklight: #CCCCCC;
}

body {
  font-family: 'Noto Sans', sans-serif;
  text-align: center;
  color: var(--light);
  font-size: x-large;
}

.container {
  background-color: var(--medium);
  color: var(--light);
  padding: 16px;
  margin: auto;
  -webkit-tap-highlight-color: transparent;
}

.small-text {
  font-size: 14px;
}

button {
  background-color: var(--highlight);
  color: var(--dark);
  outline: none;
  border: none;
  padding: 16px;
  width: 100%;
  margin-top: 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

button:disabled {
  background-color: var(--dark-highlight);
  color: var(--dark);
  cursor: not-allowed;
}

button:disabled:hover {
  background-color: var(--dark-highlight);
}

.button-loading {
  cursor: wait !important;
}

.react-datepicker button {
  margin-top: 0px !important;
}

.Toastify__close-button {
  display: none;
}

.btn-medium {
  background-color: var(--medium);
  color: var(--light);
}

input {
  background-color: var(--medium);
  width: 100%;
  padding: 8px;
  height: 3em;
  outline: none;
  border: none;
  color: var(--light);
  box-sizing: border-box;
}

input::placeholder {
  color: var(--darklight);
}

input:focus::placeholder {
  color: transparent;
}

input:disabled {
  cursor: not-allowed;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--medium);
}

.icon {
  filter: invert(1);
}

.footer {
  color: var(--light);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.footer div {
  position: absolute;
  bottom: 16px;
}

.footer div a {
  color: var(--light);
  font-size: .6em;
  font-style: normal;
}

.footer div a:hover {
  color: var(--darklight);
}

.highlight {
  color: var(--highlight);
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  filter: brightness(0.8);
}

textarea {
  background-color: var(--medium);
  resize: vertical;
  color: white;
  outline: none;
  border: none;
  min-height: 2em;
  padding: 0.5em;
  font-family: 'Noto Sans', sans-serif;
}

input[type="radio"] {
  cursor: pointer;
}

/* Remove the default appearance and set custom styles */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--highlight);
  border-radius: 50%; /* to make the border circular */
  width: 2.5em;
  height: 2.5em;
  outline: none;
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  margin-right: 16px;
  margin-left: 16px;
  transform: translate(0%, 25%);
}

/* Style the dot in the middle */
input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 1.6em;
  height: 1.6em;
  background-color: var(--highlight);
  border-radius: 50%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 2em;
  height: 2em;
  background-color: transparent;
  border: 2px solid var(--highlight);
  display: grid;
  place-content: center;
  border-radius: 100%;
}

input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 1em;
  border-radius: 100%;
  transform: scale(0);
  transition: 100ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--highlight);;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]+span {
  display: inline;
  font-size: 0.5em;
  margin-left: 0.3em;
}

.switch {
  border: 2px solid var(--highlight);
  border-radius: 1em;
  display: flex;
  width: 10em;
  height: 1.75em;
  margin-top: 0.5em;
  cursor: pointer;
}

.switch > div {
  flex: 3;
  justify-content: center;
  justify-items: center;
  text-align: center;
}

.switch label {
  cursor: pointer;
}

.switch > div:first-child {
  border-right: 2px solid var(--highlight);
  border-top-left-radius: 0.75em;
  border-bottom-left-radius: 0.75em;
}

.switch > div:last-child {
  border-top-right-radius: .75em;
  border-bottom-right-radius: .75em;
}

.switch-active {
  background-color: var(--highlight);
  color: var(--dark);
}

label {
  font-size: 0.5em;
  cursor: text;
}


label:focus-within {
  /* Your styles here */
  color: red;
  font-weight: bold;
}

a { 
  text-decoration: none;
  color: var(--light);
}

a:hover {
  color: var(--highlight);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

table {
  font-size: 0.5em;
  border-collapse: collapse;
  margin: 0.5em;
}

td {
  padding: 0.5em;
}

td {
  border: 1px solid var(--dark-highlight);
}

.error {
  color: red;
  font-weight: bold;
  animation: blinky 1s infinite;
}

@keyframes blinky {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.75; }
}

.termsOfDeliveryModal b {
  display: inline-block;
  margin-top: 2em;
  margin-bottom: 1em;
  color: var(--highlight);
}

.termsOfDeliveryModal a {
  color: var(--highlight);
}

.termsOfDeliveryModal a:hover {
  color: var(--dark-highlight);
}