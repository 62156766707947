.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: var(--medium); */
  background-color: var(--dark);
  color: var(--light);
  border-radius: 8px;
  z-index: 101;
  padding: 1em;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: "thin";
  scrollbar-color: "black darkgray";
  -webkit-scrollbar-width: "thin";
  -webkit-scrollbar-color: "black darkgray";
}

@media screen and (min-width: 800px) { 
  .modal-business {
    left: calc(50% + 5em);
  }
}
  
.modal::-webkit-scrollbar {
  width: 12px;
}

.modal::-webkit-scrollbar-track {
  background-color: var(--dark-highlight);
}

.modal::-webkit-scrollbar-thumb {
  background-color: var(--highlight);
}