.slider-container {
  position: relative;
  width: 100%;
  height: 1.65em;
  margin-bottom: 2em;
  margin-top: 1em;
  padding: 0;
  overflow: hidden;
  background-color: goldenrod;
  border-radius: 1em;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0;
  height: 3em;
  width: 100%;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3em;
  height: 100%;
  background-color: var(--highlight);
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.slider-tail {
  background-color: var(--highlight);
  filter: brightness(0.6);
  width: 100%;
  height: 3em;
  padding: 0;
  margin: 0;
  position: absolute;
  transform: translateY(-2em) translateX(-95%);
  z-index: 1;
}

.slider::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/
  background-color: var(--highlight);
  height: 3em;
  width: 3em;
  cursor: pointer;
  border-radius: 100%;
  z-index: 3;
}

/* Style the track of the slider */
.slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 3em;
  padding: 0;
  background-color: var(--medium);
}

.slider-text {
  font-size: 0.5em;
  transform: translateY(-4em);
  line-height: 3.5em;
  pointer-events: none
}


