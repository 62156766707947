.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: .5em;
  cursor: pointer;
  user-select: none;
  opacity: 1;
  height: 1.5em;
}

.select img {
  display: inline-block;
}

.select-values {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.5em;
  padding: 1em;
  display: inline-block;
}

.select-options {
  position: absolute;
  opacity: 1;
  width: 100%;
  max-height: 10em;
  overflow-y: auto;
  z-index: 1000;
  background-color: var(--medium);
}

.select-options * {
  cursor: pointer !important;
}

.select-item {
  background-color: var(--medium);
  cursor: pointer;
}