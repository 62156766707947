html {
  scroll-behavior: smooth;
}

.menu {
  margin-bottom: 100px;
  max-width: 40em;
  margin: auto;
}

.product-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #222222 #000;
  -webkit-scrollbar-width: thin;
  -webkit-scrollbar-thumb: #222222;
  -webkit-scrollbar-track: #000;
  margin-left: 0.5em;
  margin-right: 0.5em;
  align-items: stretch;
}

.product-list::-webkit-scrollbar {
  display: none;
}

#checkout-button {
  background-color: var(--dark);
  position: fixed;
  width: 100%;
  max-width: 100%;
  bottom: 0;
  padding: 8px;
  text-align: center;
}

#checkout-button button {
  width: 15em;
  max-width: 15em;
  cursor: pointer;
  margin-bottom: 8px;
}

#delivery-code {
  border: 6px dotted var(--highlight);
  width: 3em;
  height: 3em;
  border-radius: 100%;
  margin: auto;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#delivery-code.ready {
  animation: orderReady 12s infinite;
}

#delivery-code.ready>div {
  animation: orderReadyReverse 12s infinite;
}

@keyframes orderReady {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes orderReadyReverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.queuedot {
  margin-top: 8px;
  margin-right: 4px;
  margin-left: 4px;
  background-color: var(--dark-highlight);
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  display: inline-block;
  transition: width 5s ease-in-out, transform 5s ease-in-out, opacity 5s ease-in-out;
  font-size: 0.5em;
  line-height: 2.5em;
  text-align: center;
  vertical-align:top;
}

.queuedot.you {
  background-color: var(--highlight);
}

.queuedot-exit {
  animation: exit 0.5s ease-in-out forwards;
}

@keyframes exit {
  0% {
    transform: translateX(0%);
    width: 32px;
    opacity: 1;
  }
  90% {
    transform: translateX(200%);
    width: 32px;
    opacity: 0;
  }
  100% {
    transform: translateX(200%);
    width: 0px;
    opacity: 0;
  }
  
}

.blink {
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    background-color: var(--medium);
  }

  50% {
    background-color: #131212;
  }

  100% {
    background-color: var(--medium);
  }
}

#tag-selection {
  width: 100vw;
  overflow: hidden;
  max-width: 40em;
  background-color: var(--dark);
  animation: rolldown 1s forwards;
}

#tag-selection > div {
  width: 100%;
  max-width: 15em;
  padding: .5em;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}

#last-call {
  position: fixed;
  bottom: 0;
  color: var(--highlight);
  font-size: .6em;
  margin: auto;
  text-align: center;
  background-color: black;
  width: 100%;
}

.tag-listing {
  background-color: var(--medium);
  width: 4.5em;
  height: 4.5em;
  max-width: 28%;
  margin: .2em;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  animation: fadedown 1s;
  cursor: pointer;
}

.tag-listing p {
  text-align: center;
  margin-top: 1em;
  font-size: .5em;
  text-align: center;
}


.termsOfDeliveryModal b {
  display: inline-block;
  margin-top: 2em;
  margin-bottom: 1em;
  color: var(--highlight);
}

.termsOfDeliveryModal a {
  color: var(--highlight);
}

.termsOfDeliveryModal a:hover {
  color: var(--dark-highlight);
}

.product-tab {
  cursor: pointer;
  background-color: #111;
  margin: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.product-tab > div {
  animation: tab-rolldown .75s forwards;
  margin-left: 0em !important;
}

.product-tab .icon {
  margin-right: .5em;
  margin-top: .25em;
  float: right;
}

@keyframes tab-rolldown {
  0% {
    max-height: 0em;
    overflow-y: hidden;
  }
  90% {
    max-height: 20em;
  }
  100% {
    max-height: 50em;
    overflow-y: visible;
  }
}

@keyframes rolldown {
  0% {
    height: 0em;
    overflow-y: hidden;
  }
  100% {
    height: 100vh;
    overflow-y: visible;
  }
}

@keyframes fadedown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadedelay {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}