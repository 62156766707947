.spinner {
  width: 32px;
  height: 32px;
  border: 5px dotted var(--highlight);
  border-radius: 100%;
  animation: spin 5s infinite;
}

.spinner-full {
  width: 3.5em;
  height: 3.5em;
  border-width: 0.5em;
}

.spinner-parent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}