.glow-div {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Makes the div round */
  position: absolute;
  left: 50%;
  top: 1em;
  transform: translate(-50%, 0%);
  background: radial-gradient(ellipse at center, #050505 0%, transparent 80%);
  z-index: 1;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}